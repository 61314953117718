.footer_container {
  .footer {
    &_section {
      background-color: $other;
      padding: 50px 10px;
    }
    &_item {
      //padding: 20px;
      max-width: 400px;
      @include flex();
      flex-direction: column;
      justify-content: flex-start;
      min-height: 200px;
      p {
        color: $white;
      }
      @media (max-width: 768px) {
        margin-bottom: 25px;
        width: 100%;
        max-width: 100%;
        form,
        ul {
          width: 100%;
        }
      }
      li {
        display: flex;
        margin: 10px 0;
        gap: 15px;
        width: 100%;

        span {
          text-align: left;
        }
      }
      &_title {
        margin-bottom: $margin;
        color: $white;
        width: 100%;
        text-align: start;
      }
    }

    &_wraper {
      @include flex(space-between);
      flex-wrap: wrap;

      @media (max-width: 768px) {
        &:nth-child(1) {
          margin-bottom: 0;
        }
      }

      //border-top: 1px solid #eee;
      //gap: 10px;
    }
    &_link {
      color: $white;
      text-decoration: underline;

      &_container {
        @include flex(flex-start);
        gap: 20px;
        & div + div::before {
          position: absolute;
          content: "";
          left: -10px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 17px;
          background-color: $white;
        }
      }
    }
    &_copy_right {
      background-color: $color11;
      color: $white;
      padding: $margin;
      span {
        color: $white;
      }

      &_wraper {
        display: flex;
        @include flex(space-between);
        @media (max-width: 750px) {
          flex-direction: column;
        }
      }
    }
    &_logo {
      height: 55px;
      width: 125px;
      img {
        @include cover_img(contain);
      }
    }
  }
  .icon {
    &_ctn {
      display: flex;
      gap: 15px;
      width: 100%;
      margin: 5px 0;
    }
    &_item {
      background-color: $color2;
      height: 35px;
      width: 35px;
      @include flex();
      border-radius: 50%;

      svg {
        height: revert-layer;
      }

      path {
        color: $white;
        font-size: 10px;
      }
    }
  }
}
