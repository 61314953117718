@mixin flex($direction: center) {
  display: flex;
  justify-content: $direction;
  align-items: center;
}

@mixin cover_img($fit: cover) {
  //width: 100%;
  height: 100%;
  object-fit: $fit;
}

@mixin box_shadow($a: 14px, $b: 16px) {
  box-shadow: 0 $a $b 0 rgba(0, 0, 0, 0.1);
  //box-shadow: 0px 4px 6px rgb(0 0 0 / 5%), 0px 2px 4px rgb(0 0 0 / 5%),
  //0px 1px 2px rgb(0 0 0 / 10%);
}
