.website_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  footer {
    z-index: -3 !important;
  }

  main {
    //padding-top: 100px;
    min-height: calc(100vh - (85px + 415px));
    z-index: -1;
    margin-top: 85px;
  }
}
