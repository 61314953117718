.home_container {
  .reverse {
    flex-direction: row-reverse;
  }
  h2 {
    margin-bottom: 20px;
  }
  .cta_container {
    @include flex();
    gap: 25px;
  }
  .about {
    &_section {
      margin: 50px 0;
    }
  }
  .value {
    &_container {
      background-color: $color5;
      // min-height: 600px;
      margin: 50px 0;
      padding: 50px 0;
      @media screen and (max-width: 900px) {
        margin: 20px 0;
        padding: 25px 0;
      }
    }
    &_section {
      //padding: $margin 0;
      @include flex(space-between);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media (min-width: 770px) and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
      }
      &_item {
        // margin: $margin 0;
        box-shadow: 25px 0 20px -20px rgba(0, 0, 0, 0.45);
        border-radius: 8px;
        background-color: $white;
        height: 100%;
        //min-height: 350px;
        padding: 20px;
        transition: 0.5s;
        &_title {
          margin-bottom: 25px;
          text-align: center;
          line-height: 30px;
          //color: $white;
        }

        &:hover {
          background-color: $color1;
          .value_section_item_title,
          p {
            color: $white;
          }
        }
      }
    }
    &_icon {
      @include flex();
      background-color: $color1;
      height: 40px;
      width: 40px;
      margin: 0 auto;
      margin-bottom: 25px;
      border-radius: 50%;
      svg {
        height: 25px;
        width: 25px;
      }
      path {
        color: $white;
      }
    }
  }
  .container {
    &_section {
      margin: 50px 0;
      @media screen and (max-width: 900px) {
        margin: 20px 0;
      }
    }
    &_wraper {
      display: flex;
      width: 100%;
      gap: 35px;
      flex-wrap: wrap;
    }
    &_content {
      width: 48%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
    &_image {
      width: 48%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
      img{
        width: 100%;
        @include cover_img();
      }
    }
  }
  .project {
    &_container {
      margin: 50px 0;

      h2 {
        margin-bottom: 25px;
      }
    }
    &_grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media (min-width: 770px) and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .stats {
    &_container {
      background-color: $other;
      // min-height: 600px;
      padding: 50px 0;
      margin: 50px 0;
      @media screen and (max-width: 900px) {
        margin: 20px 0;
        padding: 5px 0;
      }
    }
    &_section {
      padding: $margin 0;
      @include flex(space-between);
      flex-wrap: wrap;
      gap: 20px;
      &_item {
        margin: $margin 0;
        @media screen and (max-width: 900px) {
          margin: 10px 0;
        }
        li {
          margin: 5px 0;
          color: #a3a1a1;
        }
        &_title {
          margin-bottom: 25px;
          color: $white;
        }
      }
    }
  }
  .client {
    &_container {
      // min-height: 600px;
      margin: 50px 0;
    }
    &_section {
      @include flex();
      flex-direction: column;
      margin: $margin 0;
      h2 {
        margin-bottom: 15px;
      }
      p {
        text-align: center;
        margin-bottom: 20px;
      }

      &_item {
        @include flex();
        flex-direction: column;
        margin: 0 auto;
        &_title {
          margin-bottom: 20px;
          color: $white;
          text-align: center;
        }
        h6,
        span,
        p {
          max-width: 400px;
          text-align: center;
          margin: 0 auto;
          padding: 4px 0;
        }
      }
    }
    &_img {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 50%;
      margin: 0 auto;
      img {
        @include cover_img();
      }
    }
    &_item {
    }
  }

  .team {
    &_container {
      // background-color: $color6;
      margin: 50px 0;
      padding: 20px;
      h2 {
        text-align: center;
        margin: 25px 0;
      }
    }
    &_wraper {
      @include flex();
      flex-wrap: wrap;
    }
    &-member {
      //width: 100%;
      //height: 100%;
      @include flex();
      flex-direction: column;
      margin-bottom: 20px;

      &-img {
        width: 220px;
        height: 210px;
        border-radius: 100%;
        border: 5px solid $color1;
        cursor: pointer;
        overflow: hidden;

        img {
          @include cover_img();
        }

        &:hover {
          ul {
            left: 50%;
            z-index: 10;
            top: 50%;
            transform: translate(-50%, -50%) !important;
          }
          &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color11;
            opacity: 0.6;
          }
        }

        li {
          @include flex();
          height: 40px;
          width: 40px;
          background-color: $color1;
          margin-bottom: 5px;
          cursor: pointer;
          transition: 1s;
          border-radius: $radius;

          &:hover {
            background-color: $color11;
          }
          svg {
            height: 20px;
            width: 20px;
          }
          path {
            color: $white;
          }
        }
        li {
          transition: 1s;
          margin: 5px;
        }

        ul {
          position: absolute;
          @include flex();
          left: -128px;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: 0.5s;
          z-index: -1;
          margin: 0;
          padding: 0;
        }
      }
      &-name {
        text-transform: uppercase;
        //margin:5px;
      }
      &-title {
      }
      &-desc {
        @include flex();
        flex-direction: column;
        margin-top: 15px;
      }
      &-container {
        padding: $margin 0;
        h3 {
          color: $color1;
          text-align: center;
          margin-bottom: 10px;
        }
      }
      &-wraper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 25px;
        margin: $margin;
        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }
        @media (min-width: 770px) and (max-width: 1000px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  .partner {
    height: 78px;
    width: 150px;
    img {
      @include cover_img(contain);
    }
    &-section {
      @include flex(center);
      overflow-x: scroll !important;
      height: 100%;
      gap: 20px;
      &-container {
        height: 250px;
        background-color: $color4;
      }
    }
  }
}
