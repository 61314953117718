.plan_card {
  background-color: $white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: $radius;
  overflow: hidden;
  cursor: pointer;

  .price {
    background-color: $color1;
    color: $white;
    font-size: 20px;
    border-radius: 33px;
    padding: 8px 15px;
    position: absolute;
    top: 7px;
    right: 5px;
  }
  .plan_card {
    &_image {
      transition: 0.5s;
      //height: 380px;
      overflow: hidden;
      //margin-bottom: 20px;
      width: 100%;
      height: 200px;

      &:hover{
        img {
          transform: scale(1.1);
        }
      }

      img{
        @include cover_img();
        overflow: hidden;
        transition:.5s
      }
    }

    &_description {
        padding: 10px;
      h6 {
        text-transform: uppercase;
        margin-bottom: 7px;
      }
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 4;
        margin-bottom: 10px;
      }
    }

    &_cta {
      background-color: $color1;
      color: $white;
      padding: 3px 20px;
      border-radius: 33px;
      font-size: 12px;
    }
  }
}
