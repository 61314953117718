@charset 'UTF-8';

//font aclonica
@font-face {
  font-family: Aclonica-Regular;
  src: local("Aclonica-Regular"),
    url(../../assets/fonts/Aclonica/Aclonica-Regular.ttf);
}

//font montserrat
@font-face {
  font-family: Montserrat-Regular;
  src: local("Montserrat-Regular"),
    url(../../assets/fonts/Montserrat/Montserrat-Regular.ttf);
}
@font-face {
  font-family: Montserrat-Medium;
  src: local("Montserrat-Medium"),
    url(../../assets/fonts/Montserrat/Montserrat-Medium.ttf);
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: local("Montserrat-SemiBold"),
    url(../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: Montserrat-Bold;
  src: local("Montserrat-Bold"),
    url(../../assets/fonts/Montserrat/Montserrat-Bold.ttf);
}
@font-face {
  font-family: Montserrat-ExtraBold;
  src: local("Montserrat-ExtraBold"),
    url(../../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf);
}

//Roboto
@font-face {
  font-family: Roboto-Regular;
  src: local("Roboto-Regular"),
    url(../../assets/fonts/Roboto/Roboto-Regular.ttf);
}
@font-face {
  font-family: Roboto-Medium;
  src: local("Roboto-Medium"), url(../../assets/fonts/Roboto/Roboto-Medium.ttf);
}
@font-face {
  font-family: Roboto-Bold;
  src: local("Roboto-Bold"), url(../../assets/fonts/Roboto/Roboto-Bold.ttf);
}

//general
* {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  z-index: 5;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 16px;
  outline: none;
  line-height: 1.4rem;
  color: #494244;
}
*::selection {
 // background-color: rgba(15, 33, 76, 0.1490196078);
 // color: #0f214c;
}

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color8;
    border-radius: 6px;
    //outline: 1px solid slategrey;
  }
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}
li {
  list-style-type: none;
}

img,
svg {
  height: auto;
  width: 100%;
}

aside {
  display: block;
}

a {
  transition: all 0.2s ease-in;
  text-decoration: none !important;
  color: inherit;
}
a:hover,
a:focus,
a:active {
  color: inherit;
}
.container {
  max-width: 1044px;
  margin: 0 auto;
  padding: 0 15px;
}
/* h2 {
  font-family: "Exo2-Bold", sans-serif;
  font-size: 2.2rem;
  margin-bottom: 15px;
  line-height: 2.5rem;
} */

h1 {
  font-size: 48px !important;
  line-height: 3.5rem !important;

  @media (max-width: 767px) {
    font-size: 36px !important;
    line-height: 2.4rem !important;
  }
}
h2 {
  font-size: 32px !important;
  line-height: 2.5rem !important;
  @media (max-width: 767px) {
    font-size: 24px !important;
    line-height: 2.4rem !important;
  }
}
h3 {
  font-size: 28px !important;
}
h4 {
  font-size: 24px !important;
}
h5 {
  font-size: 20px !important;
}
h6 {
  font-size: 16px !important;
  line-height: 25px !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $titlefont;
}
//form
label {
  display: block;
  margin-bottom: 5px;
  color: $color8;
}
input[type="text"],
input[type="email"],
input[type="number"],
input[type="date"],
select,
option,
input[type="password"] {
  height: 42px;
  border-radius: 6px;
  display: block;
  width: 100%;
  padding-left: 10px;
  border: 1px solid $color11;
  color: #434343;
}
input[type="checkbox"] {
  height: 20px;
  width: 20px;
  border: 1px solid $color4;
}
input:focus {
  outline: none !important;
}

.cta_btn {
  background-color: $color1;
  color: $white;
  border: none;
  outline: none;
  border-radius: $radius;
  min-width: 120px;
  padding: 12px 15px;

  &:hover {
    background-color: $color2;
  }
}
.cta_second_btn {
  background-color: $color1;
  color: $white;
  border: none;
  outline: none;
  border-radius: $radius;
  padding: 8px 15px;
  min-width: 150px;
}

.box_container {
  max-width: 1170px;
  margin: 0 auto !important;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
}

button {
  cursor: pointer;
}

.banner-container {
  //background: url(../../assets/images/banner.jpg) center/cover;
  height: 600px;
  background-position: center;
  background-size: cover;

  &::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($color2, 0.7);
  }
}
.banner_description {
  @include flex();
  flex-direction: column;
  height: 100%;
  h1 {
    text-align: center;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 15px;
  }
  p {
    text-align: center;
    color: $white;
  }
}
.form_input_ctn {
  display: flex;
  padding-bottom: 5px;
  align-items: center;
  gap: 2px;
  border-bottom: 1px solid $white;
  margin-bottom: 30px;
  width: auto;
  input,
  textarea,
  select {
    background-color: transparent;
    border: none;
    //color: $white;
    &:focus {
      outline: none;
      border: none;
    }
    width: 100%;
  }
}
.form_input_icon {
  path {
    color: $white;
  }
}

.price_warning {
  margin-bottom: 20px;
  //color: rgb(247, 73, 20);
  color: orange;
  //font-size: 15px;
  font-weight: bold;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 30; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  // background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  overflow: hidden;
  @include flex();
  &::-webkit-scrollbar {
    display: none;
  }
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 35px auto; /* 15% from the top and centered */
  padding: 40px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  height: auto;
  max-width: 1000px;
  border-radius: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  //min-height: 70vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;

  @media (max-width: 950px) {
    padding: 20px;
  }
}

/* The Close Button */
.close {
  //color: #aaa;
  color: rgb(199, 5, 5);
  //float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

.modal-content form {
  width: 100%;
  margin: 35px 0;
  padding: 35px;
  background-color: #f9f9f9;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid $color1;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 15px auto;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
