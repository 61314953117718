.header_container {
  max-height: 85px;
  position: fixed;
  //padding: 15px 0;
  width: 100%;
  height: 100%;
  z-index: 100 !important;
  transition: 0.8s;
  background-color: $white;
  @media (min-width: 950px) {
    @include box_shadow();
  }
  transition: 0.5s;

  .header_default {
    background-color: $color1;
    color: $white;
  }

  .burger_menu {
    display: none;
    @media (max-width: 950px) {
      display: block;
    }

    svg {
      height: 35px;
      width: 35px;
    }
  }

  .nav-active {
    @keyframes letAnim {
      /*   to ou 100% c'est la mÃĒme chose en animation */
      100% {
        transform: translateX(-50%) scaleX(1);
      }
    }
    @media (max-width: 950px) {
      color: $color1;
    }
    @media (min-width: 950px) {
      &::after {
        content: "";
        position: absolute;
        background: $color1;
        width: 100%;
        height: 3px;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%) scaleX(0);
        animation: letAnim 0.5s ease-in-out forwards;
        transform-origin: left;
      }
    }
  }
  .header {
    &_wraper {
      @include flex(space-between);
      height: 100%;
    }
    &_logo {
      &_container {
        width: 60px;
        height: 100%;
        display: block;

        img {
          @include cover_img(contain);
        }
      }
      // @include m
    }
    &_links {
      @include flex();
      a {
        margin: 0;
        padding: 0;
        text-transform: uppercase;
      }
      &_container {
        @media (max-width: 950px) {
          display: none;
        }
        @include flex();
        gap: 15px;
      }
    }
    &_mobile_links {
      @include flex();

      a {
        margin: 0;
        transition: 0.5s;
        padding: 0;
        text-transform: uppercase;
        margin: 10px 0;
      }
      &_container {
        position: absolute;
        top: 85px;
        left: 0;
        background-color: $white;
        display: none;
        gap: 15px;
        width: 100%;
        transition: 5s;
        height: 0;
      }
    }
  }
}
