.privacy_container {
  .privacy {
      &_container {
        padding: 10px 0;
      }
      &_wraper {
        margin: 15px 0;
      }
      &_title {
        font-size: 22px;
        margin-bottom: 10px;
        font-weight: bold;
      }
      &_desc {
        text-align: justify;
      }
    
  }
}
