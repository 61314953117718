.contact_container {
  .contact {
    &_section {
      padding: 50px 10px;
    }
    &_info {
      gap: 25px;
      @include flex(space-between);

      @media (max-width: 950px) {
        flex-wrap: wrap;
      }
    }
    &_item {
      @include flex(flex-start);
      flex-direction: column;
      align-items: flex-start;
      min-height: 100px;
      max-width: 400px;
      &_title {
        margin-bottom: 20px;
        text-align: left;
      }
      li {
        margin: 7px 0;
      }
    }
    &_form {
      //@include flex();
      margin: $margin 0;
      h2 {
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      form {
        width: 100%;
        margin: $margin 0;
        padding: $margin;
        background-color: #f9f9f9;
      }
      .first_row {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        width: 100%;
        @media (max-width: 950px) {
          .form_input_ctn {
            width: 100% !important;
          }        }
        .form_input_ctn {
          width: 32%;
        }
      }

      .form_input_ctn {
        border-bottom: 1px solid $other;
        width: auto;
        input,
        textarea {
          background-color: transparent;
          border: none;
          color: $other;
          &:focus {
            outline: none;
            border: none;
          }
        }
      }
      .form_input_icon {
        path {
          color: $color2;
        }
      }
    }
  }
  .icon {
    &_ctn {
      display: flex;
      gap: 15px;
      width: 100%;
      margin: 5px 0;
    }
    &_item {
      //background-color: $color2;
      height: 35px;
      width: 35px;
      @include flex();
      border-radius: 50%;
      border: 1px solid $color2;

      svg {
        height: revert-layer;
      }

      path {
        color: $color11;
        font-size: 10px;
      }
    }
  }
}
