.detail_container {
  .img_ctn {
    width: 100%;
    max-height: 450px;
    img {
      @include cover_img(contain);
    }
  }
  .detail {
    &_image {
      padding: 20px 10px;
    }
    &_section {
      padding: 30px 10px;
      h2 {
        margin-bottom: 8px;
      }
    }
    &_description {
      background-color: $color2;
      margin: 25px 0;
      padding: 25px;
      li {
        list-style-type: disc !important;
      }
      * {
        color: $color5;
      }
    }
    &_grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media (min-width: 770px) and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .comment {
    margin: 10px 0;
    &_section {
    }
    &_ctn{
      margin: 20px $margin 0;
      p{
        margin: 5px 0;
      }
    }
    &_form {
      background-color: #f9f9f9;
      //@include flex();
      margin: $margin 0;
      margin-top: 0;  
      h2 {
        padding: $margin;
        padding-bottom: 0;
        text-transform: uppercase;
        //margin-bottom: 10px;
      }
      form {
        width: 100%;
        margin: $margin 0;
        padding: $margin;
        padding-top: 0;
      }
      .first_row {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        width: 100%;
        @media (max-width: 950px) {
          .form_input_ctn {
            width: 100% !important;
          }
        }
        .form_input_ctn {
          width: 32%;
        }
      }

      .form_input_ctn {
        border-bottom: 1px solid $other;
        width: auto;
        input,
        textarea {
          background-color: transparent;
          border: none;
          color: $other;
          &:focus {
            outline: none;
            border: none;
          }
        }
      }
      .form_input_icon {
        path {
          color: $color2;
        }
      }
    }
  }
}
