.catalogue_container {
  .catalogue {
    &_section{
        padding: 50px 10px;
    }
    &_grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media (min-width: 770px) and (max-width:1000px){
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
