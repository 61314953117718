.project_card {
  //background-color: $white;
  background-position: center;
  background-size: cover;
  border-radius: $radius;
  overflow: hidden;
  cursor: pointer;
  min-height: 320px;
  margin-bottom: 15px;
  @include flex();
  flex-direction: column;
  padding: 0 25px;

  &:hover {
    .project_card_description {
      display: block;
    }
    &::before {
      content: "";
      position: absolute;
      background-color: rgba($color11, $alpha: 0.8);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  .project_card {
    &_description {
      display: none;
      color: $white;
      padding: 10px;
      h6 {
        color: $white;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 20px;
        color: $white;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 6;
        //margin-bottom: 10px;
      }
    }

    &_cta {
      background-color: $color1;
      color: $white;
      padding: 3px 20px;
      border-radius: 33px;
      font-size: 12px;
    }
  }
}
